import React, { Component } from 'react'
import axios from 'axios'
import TextRender from './renderText'
const parseString = require('xml2js').parseString

class Steps extends Component {
  constructor() {
    super()
    this.state = {
      Steps: []
    }
  }
  componentDidMount() {
    axios.get(`xmls/steps.xml`).then(response => {
      //console.og(response)
      parseString(response.data, (error, result) => {
        if (error) {
          //console.log('error')
        } else {
          this.setState({
            Steps: [...result['Spirathon']['Steps'][0]['Description']]
          })
        }
      })
    })
  }

  render() {
    return (
      <main>
        <div className='elseall clearfix'>
          <div className='container'>
            <div className='topp wd-100 clearfix'>
              <div className='col col-3_5 col-sm-1_1'>
                <h1>How to Participate in Spirathon?</h1>
                <div className='col col-1_3 col-sm-1_1'>
                  <label>
                    You will need to register your team through our online registration form. You can find the registration form which you need to update after signing up for the event.
                  </label>
                </div>
              </div>
              <div className='col col-2_5 col-sm-1_1'>
                <div className='thumbnail-box2 hide-sm'>
                  <img
                    src='/assets/images/graphics/registration_nobg-02.png'
                    alt=''
                  />
                </div>
              </div>
            </div>
            {this.state.Steps.map((item, index) => {
              {
                /* //console.log(item) */
              }
              return (
                <div
                  className={
                    'wd-100 clearfix steps ' + (index % 2 === 0 ? 'lr' : '')
                  }
                  key={index}
                >
                  <div className='col col-1_5 hide-sm blank'>
                    <div />
                  </div>
                  <div className='col col-2_5 col-pt-3_5 col-sm-1_1 step clearfix'>
                    <div className='col col-1_4'>
                      <h1>
                        {index + 1}
                      </h1>
                    </div>
                    <div className='col col-2_4'>
                      <h6>
                        <TextRender text={item.Content[0]} />
                      </h6>
                    </div>
                  </div>
                  <div className='col col-2_5 col-pt-2_5 col-sm-1_1'>
                    <div className='thumbnail-box2'>
                      <img
                        src={item.image[0].src[0]}
                        alt={item.image[0].title[0]}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </main>
    )
  }
}

export default Steps
