import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { disableWorkShop1 } from "../../shared/workshopDisable";
const parseString = require("xml2js").parseString;

class SpeakersPage extends Component {
  constructor() {
    super();
    this.state = {
      Speakers: []
    };
  }
  componentDidMount() {
    axios.get(`/xmls/speakers.xml`).then(response => {
      parseString(response.data, (error, result) => {
        if (error) {
          //console.log('error')
        } else {
          this.setState({
            Speakers: [...result["Spirathon"]["Event"][0]["Description"]]
          });
        }
      });
    });
  }
  renderSpeaker = itemSpeaker => {
    //console.og(itemSpeaker)
    return itemSpeaker.map((item, index) => (
      <div className="col col-1_4 col-pt-1_4 col-sm-1_2" key={index}>
        <div className="textbox">
          <div className="thumbnail-box2">
            <img src={item.image[0].src[0]} alt={item.image[0].title[0]} />
          </div>
          <h2>{item.Name[0]}</h2>
          <h6>{item.Institution[0]}</h6>
        </div>
      </div>
    ));
  };
  render() {
    return (
      <main>
        <div className="elseall clearfix">
          <div className="container">
            {/* <h1 className="t-a-c cullprit">Updating soon</h1> */}
            <div className="topp wd-100 clearfix">
              <div className="col col-3_5 col-sm-1_1">
                <h1>Speakers for the Main Event.</h1>
                {/* <div className="col col-1_3 col-sm-1_1">
                  <label>
                    These speakers will be sharing their knowledge on AI for the
                    on the event.
                  </label>
                </div> */}
              </div>
              {/* <div className="col col-2_5 col-sm-1_1">
                <div className="thumbnail-box2 hide-sm">
                  <img src="/assets/images/graphics/workshop_1-02.png" alt="" />
                </div>
              </div> */}
            </div>
            {this.state.Speakers.map((item, index) => {
              const mySpeaker = item.Speaker;
              return (
                <div
                  className={
                    "wd-100 clearfix steps ws " + (index % 2 === 0 ? "lr" : "")
                  }
                  key={index}
                >
                  <div className="col col-1_6 hide-sm blank">
                    <div />
                  </div>
                  <div className="col col-2_6 col-pt-2_6 col-sm-1_1 step clearfix">
                    <div className="col col-1_5">
                      <h1>{index + 1}</h1>
                    </div>
                    <div className="col col-4_5">
                      <h3>{item.Title[0]}</h3>
                      <label>{item.Content[0]}</label>
                    </div>
                  </div>
                  {this.renderSpeaker(mySpeaker)}
                </div>
              );
            })}
            <div className="top wd-100 clearfix judges">
              <img className="desktop" src="assets/images/judges/judges-01.jpg" alt="judges" />
              <img className="mobile" src="assets/images/judges/judges-02.jpg" alt="judges" />
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default SpeakersPage;
