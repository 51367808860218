import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import UserNavLink from './UserNavlink';

class RulesPage extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    if (!this.props.isLoggedIn) return <Redirect to='/signin' />
    else {
      return (
        <div className='elseall wd-100 clearfix'>
          <div className='container'>
            <UserNavLink />
            <div className='restbody'>
              <div className='col col-1_1'>
                <div className='padlr-15 mrgb-30 mrgt-30'>
                  <h2>
                    Rules/Entry Requirement:
              </h2>
                  <ol className="rule-list">
                    <li>
                      1. The contest is open to the public. <br />
                    </li>
                    <li>
                      2. Participants may only join one team.<br />
                    </li>
                    <li>
                      3. Teams will be required to elect a team lead or team representative who will act as a contact point with the Spirathon organizers.<br />
                    </li>
                    <li>
                      4. Judges’ decisions are final and are not subject to review.<br />
                    </li>
                    <li>
                      5. The application should not be already existing.<br />
                    </li>
                    <li>
                      6. The application needs to have elements of AI.<br />
                    </li>
                  </ol>
                </div>
              </div>
              {/* <div className='clearfix'>
                <div className='col col-1_2 col-sm-1_1 col-pt-2_4'>
                  <div className='padlr-15 mrgb-30 mrgt-30'>
                    <h2>
                      Rules to follow
                </h2>
                    <p>
  
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Ut adipisci aspernatur nisi maiores cumque at voluptatem quod ducimus odio cupiditate architecto
                      culpa,
                      dolorem eius officia, itaque eligendi rerum, dicta distinctio!
                </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Ut vitae consectetur sequi.
                      Eligendi eos vero accusamus, magni voluptates cum sequi similique animi.
                      Obcaecati ad fugit voluptatem ab aliquid nihil nemo!
                </p>
                  </div>
                </div>
                <div className='col col-1_2 col-sm-1_1 col-pt-2_4'>
                  <div className='padlr-15 mrgb-30 mrgt-30'>
                    <h2>
                      Dis qualification Criteria
                </h2>
                    <p>
  
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Ut adipisci aspernatur nisi maiores cumque at voluptatem quod ducimus odio cupiditate architecto
                      culpa,
                      dolorem eius officia, itaque eligendi rerum, dicta distinctio!
                </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Ut vitae consectetur sequi.
                      Eligendi eos vero accusamus, magni voluptates cum sequi similique animi.
                      Obcaecati ad fugit voluptatem ab aliquid nihil nemo!
                </p>
                  </div>
  
                </div>
              </div> */}
            </div>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn
  }
}

export default connect(mapStateToProps, null)(RulesPage)
