import React, { Component } from 'react'
import { NavLink, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Proptypes from 'prop-types'
import { signUp, checkFormDisabled } from '../../store/actions/authActions'
import { disableForm } from '../../shared/shared'
import { getFromLocalStorage } from '../../shared/shared'

const closedStyle = {
  color: '#fff',
  fontSize: '40px',
}
class Register extends Component {
  state = {
    email: '',
    password: '',
    confirmPassword: '',
    phone: ''
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    //console.og(this.state)
    if (this.props.formDisabled !== true && !disableForm()) {
      this.props.signup(this.state)
    } else {
      //console.log('Registration period has ended')
    }
  }

  componentDidMount() {
    this.props.checkFormDisabled()
  }

  render() {
    const id = getFromLocalStorage('userId')
    if (this.props.isLoggedIn) return <Redirect to={`/team/edit/${id}`} />
    return (
      <main>
        <div className='elseall clearfix'>
          <div className='container'>
            <div className='wd-100 clearfix'>
              <div className='col col-1_3 col-sm-1_1'>
                <form
                  className='form-area clearfix'
                  onSubmit={this.handleSubmit}
                ><h1 style={closedStyle}>Team Registration Closed for Spirathon</h1>
                  {/* <fieldset disabled={this.props.formDisabled}>
                    <h1 className='show-sm'>
                      Sign up
                  </h1>
                    <input
                      id='email'
                      type='text'
                      placeholder='Enter your email'
                      value={this.state.email}
                      onChange={this.handleChange}
                      required />
                    <input
                      id='phone'
                      type='tel'
                      pattern='^[+]?[0-9]{9,13}$'
                      title='must be a valid phone number'
                      placeholder='Enter your phone number'
                      value={this.state.phone}
                      onChange={this.handleChange}
                      required />
                    <input
                      id='password'
                      type='password'
                      placeholder='Enter your password'
                      value={this.state.password}
                      onChange={this.handleChange}
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                      required />
                    <input
                      id='confirmPassword'
                      type='password'
                      placeholder='Re-enter your password'
                      value={this.state.confirmPassword}
                      onChange={this.handleChange}
                      required />
                    <button className='btn submit-btn'>
                      Registration closed
                  </button>
                  </fieldset> */}
                </form>
              </div>
              <div className='col col-1_3 col-md-2_3 col-sm-1_1'>
                <section className='description'>
                  <div className='metop hide-sm'>
                    <h1>
                      sign up
                    </h1>
                  </div>
                  <div className='thumbnail-box hide-sm'>
                    <img
                      src='/assets/images/graphics/sign up-02.png'
                      alt=''
                    />
                  </div>
                  <div className='mebottom'>
                    <h5>
                      Already signed up?<br />
                      <NavLink to='/signin'>
                        Login here.
                      </NavLink>
                    </h5>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

Register.propTypes = {
  signup: Proptypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    email: state.auth.email,
    authError: state.auth.authError,
    isLoggedIn: state.auth.isLoggedIn,
    formDisabled: state.auth.formDisabled
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signup: userInfo => dispatch(signUp(userInfo)),
    checkFormDisabled: () => dispatch(checkFormDisabled())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Register)
