// authentication
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_ERROR = 'SIGNUP_ERROR'
export const SIGNIN_SUCCESS = 'LOGIN_SUCCESS'
export const SIGNIN_ERROR = 'LOGIN_ERROR'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const UPDATE_PROPS = 'UPDATE_PROPS'

// form disabling
export const FORM_DISABLED = 'FORM_DISABLED'

// team register
export const TEAM_REGISTER_SUCCESS = 'TEAM_REGISTER_SUCCESS'
export const TEAM_REGISTER_ERROR = 'TEAM_REGISTER_ERROR'

// notifications
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'
