import React, { Component } from 'react'

class RenderSquareImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imagesArray: [],
    }
  }

  imageOnLoad = ({ target: img }) => {
    //console.og(img)
    //console.og(this.state.dimensions)
    //console.og(img.naturalHeight)

    if (img.naturalHeight < img.naturalWidth) {
      document.getElementById(img.id).className += ' landscape'
    } else if (img.naturalHeight > img.naturalWidth) {
      document.getElementById(img.id).className += ' portrait'
    } else {
      document.getElementById(img.id).className += ' square'
    }
  }
  render() {
    return (
      <div className='preview-box clearfix'>
        {this.props.images.map((imageSource, index) => {
          return (
            <a
              key={index}
              href={imageSource.src[0]}
              data-fancybox='gallery'
              data-caption={imageSource.title[0]}
              className='preview-image'
            >
              <img
                id={index}
                src={imageSource.src[0]}
                alt=''
                onLoad={this.imageOnLoad}
              />
            </a>
          )
        })}

      </div>
    )
  }
}

export default RenderSquareImage
