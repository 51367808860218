import React from "react";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <main>
      <div className="relative container">
        <div className="absolute clearfix banner-boxes">
          <div className="col col-1_1 newbox newbox-2">
            {/* <section>
            <h2>What is Spirathon?</h2>
            <p>
              Spirathon is an annual appathon organised by Spiralogics
              International in partnership with Spiralogics Institution of
              Technology.
            </p>
            <Link to={"/about"} className="btn primary-btn">
              <h6>Read More &#187;</h6>
            </Link>
          </section> */}
            {/* <section>
              <h2 className="genericNishaAdvice">Spirathon AI Conference</h2>
              <p>
                The conference will include speakers with extensive knowledge on
                AI. This will be your chance to network with veterans and
                revolutionaries of the IT industry.
              </p>
              <p>
                The program will include AI Application Demo and Presentation,
                Key Note Speakers and Awards Distribution.
              </p>
              <p>
                Please make sure to apply by January 20, 2019 as we have limited
                seats.
              </p>
              <br />
              <p className="mrgb-10">
                Venue :<span> Bougainvilla, Tripureshwor</span>
              </p>
              <p className="mrgb-10">
                Date :<span> 2nd Feb, 2019</span>
              </p>
              <p className="mrgb-10">
                Time :<span> 10:30 am to 3 pm</span>
              </p>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSemgKJQ3KhEHXOMuiD4Dg0bIQdSTgCfjfxBi0aR1NvxaHjYoQ/viewform"
                target="_blank"
              >
                Participate in this Event
              </a>
              <Link to={"/about"} className="btn primary-btn">
                <h6>Read More &#187;</h6>
              </Link>
            </section> */}

            {/* <iframe
              src="https://www.youtube.com/embed/FkeSVthE5KE"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            /> */}
          </div>
          {/* <section>
            <h2>What is Spirathon?</h2>
            <p>
              Spirathon is an annual appathon organised by Spiralogics
              International in partnership with Spiralogics Institution of
              Technology.
            </p>
            <Link to={"/about"} className="btn primary-btn">
              <h6>Read More &#187;</h6>
            </Link>
          </section>
          <section>
            <h2>How to participate in Spirathon?</h2>
            <p>
              Register your team for spirathon by clicking on the ‘Participate’
              icon at the bottom.
            </p> 
           <Link to={'/signup'} className='btn seperator-btn'>Read More >>></Link> 
           <p>Registration Opens from November 30, 2018</p> 
           </section>  */}
        </div>
      </div>
      <div className="banner">
        {/* <img src="/assets/images/logo/aiforimpactlogo.png" alt="banner image" /> */}
      </div>
      {/* <div className="speakers">
        <div className="container">
          <div className="t-center">
            <h2>
              Our Speakers
            </h2>
          </div>
          <div className="col col-1_4 col-sm-1_2 t-center">
            <div className='thumbnail-box'>
              <img src='/assets/images/graphics/registration_nobg-02.png' alt='' />
            </div>
            <h5>
              Mr. khulmansig ghee singhghg
            </h5>
            <label>
              From college or institution of something.
            </label>
          </div>
          <div className="col col-1_4 col-sm-1_2 t-center">
            <div className='thumbnail-box'>
              <img src='/assets/images/graphics/registration_nobg-02.png' alt='' />
            </div>
            <h5>
              Mr. khulmansig ghee singhghg
            </h5>
            <label>
              From college or institution of something.
            </label>
          </div>
          <div className="col col-1_4 col-sm-1_2 t-center">
            <div className='thumbnail-box'>
              <img src='/assets/images/graphics/registration_nobg-02.png' alt='' />
            </div>
            <h5>
              Mr. khulmansig ghee singhghg
            </h5>
            <label>
              From college or institution of something.
            </label>
          </div>
          <div className="col col-1_4 col-sm-1_2 t-center">
            <div className='thumbnail-box'>
              <img src='/assets/images/graphics/registration_nobg-02.png' alt='' />
            </div>
            <h5>
              Mr. khulmansig ghee singhghg
            </h5>
            <label>
              From college or institution of something.
            </label>
          </div>
          <div className="col col-1_3 col-sm-1_2 t-center">
            <div className='thumbnail-box'>
              <img src='/assets/images/graphics/registration_nobg-02.png' alt='' />
            </div>
            <h5>
              Mr. khulmansig ghee singhghg
            </h5>
            <label>
              From college or institution of something.
            </label>
          </div>
          <div className="col col-1_3 col-sm-1_2 t-center">
            <div className='thumbnail-box'>
              <img src='/assets/images/graphics/registration_nobg-02.png' alt='' />
            </div>
            <h5>
              Mr. khulmansig ghee singhghg
            </h5>
            <label>
              From college or institution of something.
            </label>
          </div>
          <div className="col col-1_3 col-sm-1_2 t-center">
            <div className='thumbnail-box'>
              <img src='/assets/images/graphics/registration_nobg-02.png' alt='' />
            </div>
            <h5>
              Mr. khulmansig ghee singhghg
            </h5>
            <label>
              From college or institution of something.
            </label>
          </div>
          <div className="col col-1_4 col-sm-1_2 t-center">
            <div className='thumbnail-box'>
              <img src='/assets/images/graphics/registration_nobg-02.png' alt='' />
            </div>
            <h5>
              Mr. khulmansig ghee singhghg
            </h5>
            <label>
              From college or institution of something.
            </label>
          </div>
          <div className="col col-1_4 col-sm-1_2 t-center">
            <div className='thumbnail-box'>
              <img src='/assets/images/graphics/registration_nobg-02.png' alt='' />
            </div>
            <h5>
              Mr. khulmansig ghee singhghg
            </h5>
            <label>
              From college or institution of something.
            </label>
          </div>
          <div className="col col-1_4 col-sm-1_2 t-center">
            <div className='thumbnail-box'>
              <img src='/assets/images/graphics/registration_nobg-02.png' alt='' />
            </div>
            <h5>
              Mr. khulmansig ghee singhghg
            </h5>
            <label>
              From college or institution of something.
            </label>
          </div>
          <div className="col col-1_4 col-sm-1_2 t-center">
            <div className='thumbnail-box'>
              <img src='/assets/images/graphics/registration_nobg-02.png' alt='' />
            </div>
            <h5>
              Mr. khulmansig ghee singhghg
            </h5>
            <label>
              From college or institution of something.
            </label>
          </div>
        </div>
      </div> */}
    </main>
  );
};

export default HomePage;
