import React from 'react'

const PrizesPage = () => {
  return (
    <main>
      <div className='elseall clearfix'>
        <div className='container'>
          <div className='topp wd-100 clearfix'>
            <div className='col col-3_5 col-sm-1_1'>
              <h1>Why participate in Spirathon?</h1>
              <div className='col col-1_3 col-sm-1_1'>
                <label>
                  Winners of Spirathon will get following prizes
                </label>
              </div>
            </div>
            <div className='col col-2_5 col-sm-1_1'>
              <div className='thumbnail-box2 hide-sm'>
                <img
                  src='/assets/images/graphics/005-architecture.png'
                  alt=''
                />
              </div>
            </div>
          </div>
          <div className='wd-100 clearfix prize'>
            <div className='col col-1_1'>
              <div className='textbox'>
                <div className='thumbnail-box2'>
                  <img
                    src='/assets/images/graphics/003-chicken.png'
                    alt=''
                  />
                </div>
                <h2>
                  Winner! Winner!! Chicken Dinner!!!
                </h2>
                <h5>
                  Cash prize of Rs 20,000 + funding
                </h5>
                <h5>
                  internship & job opportunity at Spiralogics
                </h5>
                <h5>
                  trophy, gift hamper and certificates
                </h5>
              </div>
            </div>
          </div>
          <div className='wd-100 clearfix prize'>
            <div className='col col-1_2 col-sm-1_1'>
              <div className='textbox'>
                <div className='thumbnail-box2'>
                  <img
                    src='/assets/images/graphics/006-silver-medal.png'
                    alt=''
                  />
                </div>
                <h2>
                  1st runner up
                </h2>
                <h5>
                  Cash prize of Rs 10,000
                </h5>
                <h5>
                  trophy and certificates
                </h5>
              </div>
            </div>
            <div className='col col-1_2 col-sm-1_1'>
              <div className='textbox'>
                <div className='thumbnail-box2'>
                  <img
                    src='/assets/images/graphics/007-bronze-medal.png'
                    alt=''
                  />
                </div>
                <h2>
                  2nd runner up
                </h2>
                <h5>
                  Cash prize of Rs 5,000
                </h5>
                <h5>
                  trophy and certificates
                </h5>
              </div>
            </div>
          </div>
          {/* <div className='wd-100 clearfix steps prize lr'>
            <div className='col col-1_3 col-pt-1_3 col-sm-1_1'>
              <div className='thumbnail-box2'>
                <img
                  src='/assets/images/graphics/registration_nobg-02.png'
                  alt=''
                />
              </div>
            </div>
            <div className='col col-2_3 col-pt-2_3 col-sm-1_1 step clearfix'>
              <div className='textbox'>
                <h2>
                  Mr. khulmansig ghee singhghg
                </h2>
                <h6>
                  From college or institution of something.
                </h6>
                <h5>
                  Prize name or worth of amount
                </h5>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </main>
  )
}

export default PrizesPage
