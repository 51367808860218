import React from 'react'

const NotFound = () => {
  return (
    <main>
      <div className='elseall clearfix'>
        <div className='container'>
          <div className='wd-100 clearfix errorpage'>
            <div className='topheader'>
              <h1>
                404 Error
              </h1>
              <h2>
                Page not found
              </h2>
            </div>
            <div className='thumbnail-box'>
              <img
                src='/assets/images/graphics/registration_nobg-02.png'
                alt=''
              />
            </div>
            <p>
              Looks like the url you entered is not a valid one.
            </p>
          </div>
        </div>
      </div>
    </main>
  )
}

export default NotFound
