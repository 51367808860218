const getFromLocalStorage = keyName => {
  const value = localStorage.getItem(keyName)
  return value
}

const setToLocalstorage = (keyName, valueName) => {
  localStorage.setItem(keyName, valueName)
}

const setHeaders = () => {
  return {
    'x-access-token': `${getFromLocalStorage('token')}`
  }
}

const disableForm = () => {
  const disableDate = new Date('January 06, 2019 00:00:00')
  const todaysDate = Date.now()
  if (todaysDate >= disableDate) {
    return true
  }
  return false
}

module.exports = {
  getFromLocalStorage,
  setToLocalstorage,
  setHeaders,
  disableForm
}
