import {
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  SIGNOUT_SUCCESS,
  SIGNIN_SUCCESS,
  SIGNIN_ERROR,
  FORM_DISABLED
} from './actionTypes'
import { baseUrl } from '../../config/apiUrls'
import { disableForm } from '../../shared/shared'
import axios from 'axios'
import { toast } from 'react-toastify'

// for register
export const signUp = newParticipant => {
  //console.og(newParticipant)
  return async (dispatch, getState) => {
    try {
      const res = await axios.post(`${baseUrl}createAuthUser/`, newParticipant)
      //console.og(res)
      if (res.data.success == false) {
        toast.error(res.data.message)
        dispatch(signupFailure(res))
      } else {
        toast.success("Sign Up Successful!")
        dispatch(signupSuccess(res))
        setLocalStorage(res.data)
      }
    } catch (err) {
      console.error(err)
      toast.error("Sign Up Failed!")
      dispatch(signupFailure(err))
    }
  }
}

function signupSuccess(res) {
  return {
    type: SIGNUP_SUCCESS,
    res
  }
}

function signupFailure(err) {
  return {
    type: SIGNUP_ERROR,
    err
  }
}

// for login
export const signIn = credentials => {
  //console.og(credentials)
  return async (dispatch, getState) => {
    try {
      const res = await axios.post(`${baseUrl}authenticate/`, credentials)
      //console.og(res)
      if (res.data.success === true) {
        toast.success("Sign In Successful!")
        dispatch(signinSuccess(res))
        setLocalStorage(res.data)
      } else {
        // add servermessage here
        toast.error(res.data.message)
        dispatch(signinFailure(res.data.message))
      }
    } catch (err) {
      //console.log(err.config)
      dispatch(signinFailure(err))
    }
  }
}
function signinSuccess(res) {
  return {
    type: SIGNIN_SUCCESS,
    res
  }
}

function signinFailure(err) {
  return {
    type: SIGNIN_ERROR,
    err
  }
}

function setLocalStorage(data) {
  //console.og(data)
  localStorage.setItem('email', data.user.email)
  localStorage.setItem('token', data.token)
  localStorage.setItem('userId', data.user._id)
  localStorage.setItem('registered', data.user.isTeamRegistered)
}

// for logout
export const signOut = () => {
  return (dispatch, getState) => {
    clearLocalStorage()
    dispatch(signoutSuccess())
  }
}
function signoutSuccess() {
  return {
    type: SIGNOUT_SUCCESS
  }
}

function clearLocalStorage() {
  localStorage.clear()
}

// for disableForm

export const checkFormDisabled = () => {
  return (dispatch, getState) => {
    if (disableForm()) {
      dispatch(disableFormSuccess())
    }
  }
}

function disableFormSuccess() {
  return {
    type: FORM_DISABLED
  }
}
