import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'

class NotificationComponent extends Component {
  generateNotification(notification) {
    switch (notification.type.toUpperCase()) {
      case 'SUCCESS':
        return toast.success(notification.message)
      case 'INFO':
        return toast.info(notification.message)
      case 'ERROR':
        return toast.error(notification.message)
      case 'WARNING':
        return toast.warning(notification.message)
      default:
        return null
    }
  }
  componentWillReceiveProps(newProps) {
    Object.keys(newProps.notifications).map(notificationId => {
      this.generateNotification(newProps.notifications[notificationId])
    })
  }
  render() {
    return <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
  }
}
const mapStateToProps = ({ notifications }) => {
  return {
    notifications
  }
}
export default connect(mapStateToProps)(NotificationComponent)
