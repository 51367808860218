import React from 'react'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import UserNavLink from './UserNavlink'
import NotFound from '../layouts/NotFound'
import Opponent from './Opponent'
import TeamEdit from './TeamEdit'
import TeamView from './TeamView'
import RulesPage from './Rules'

const Dashboard = () => {
  return (
    <BrowserRouter>
      <div className='elseall wd-100 clearfix'>
        <div className='container'>
          <UserNavLink />
          <Switch>
            <Route path='/404' component={NotFound} />
            <Route path='/rules' component={RulesPage} />
            <Route path='/opponents' component={Opponent} />
            <Route path='/team/edit' component={TeamEdit} />
            <Route path='/team-view/:id' component={TeamView} />
            <Redirect from='*' to='/404' />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  )
}
export default Dashboard
