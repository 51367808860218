import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'
import { baseUrl } from '../../config/apiUrls'

class Login extends Component {
  state = {
    email: '',
    password: ''
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.signin(this.state)
  }

  render () {
    if(this.props.authError) return <div>{this.props.authError}</div>
    if (this.props.isLoggedIn) return <Redirect to='/rules' />
    return (
      <main>
        <div className='elseall clearfix'>
          <div className='container'>
            <div className='wd-100 clearfix'>
              <div className='col col-1_3 col-sm-1_1 col-pt-1_1 fr'>
                <form
                  className='form-area clearfix'
                  onSubmit={this.handleSubmit}
                >
                  <h1 className='show-sm'>
                    Log in
                  </h1>
                  <input
                    id='email'
                    type='text'
                    placeholder='Enter your email'
                    value={this.state.email}
                    onChange={this.handleChange}
                    required
                  />
                  <input
                    id='password'
                    type='password'
                    placeholder='Enter your password'
                    value={this.state.password}
                    onChange={this.handleChange}
                    required
                  />
                  <button className='btn submit-btn'>
                    Log in
                  </button>
                </form>
              </div>
              <div className='col col-1_3 col-md-2_3 col-sm-1_1 col-pt-1_1 fr'>
                <section className='description'>
                  <div className='metop hide-sm'>
                    <h1>
                      log in
                    </h1>
                  </div>
                  <div className='thumbnail-box hide-sm'>
                    <img src='/assets/images/graphics/sign in-02.png' alt='' />
                  </div>
                  <div className='mebottom'>
                    <h5>
                      <a href={baseUrl+'forgot'}>Forgot Password?</a>
                    </h5>
                    <h5>
                      Not signed in yet?<br />
                      <NavLink to='/signup'>
                        Register Now.
                      </NavLink>
                    </h5>
                    <a
                      href='
                    http://community.spiralogics.com/'
                      target='_blank'
                    >
                      Visit our community.
                    </a>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

Login.propTypes = {
  signin: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  //console.og(state)
  return {
    email: state.auth.email,
    authError: state.auth.authError,
    isLoggedIn: state.auth.isLoggedIn
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signin: userInfo => dispatch(signIn(userInfo))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
