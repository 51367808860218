import {
  TEAM_REGISTER_SUCCESS,
  TEAM_REGISTER_ERROR
} from '../actions/actionTypes'

const initialState = {
  teamError: null,
  formDisabled: false
}
const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEAM_REGISTER_SUCCESS:
      //console.log('team registration successful')
      localStorage.setItem('registered', true)
      return {
        ...state,
        teamError: null
      }

    case TEAM_REGISTER_ERROR:
      //console.log('error registering the team')
      return {
        ...state,
        teamError: action.err.message
      }

    default:
      return state
  }
}

export default teamReducer
