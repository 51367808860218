import { TEAM_REGISTER_SUCCESS, TEAM_REGISTER_ERROR, UPDATE_PROPS } from './actionTypes'
import { baseUrl } from '../../config/apiUrls'
import { setHeaders } from '../../shared/shared'
import axios from 'axios';
import { toast } from 'react-toastify'

export const teamRegister = (id, teamDetails) => {
  //console.og(teamDetails)
  return async (dispatch, getState) => {
    try {
      const res = await axios.put(`${baseUrl}v1/users/${id}`, teamDetails, { headers: setHeaders() })
      toast.success("Team Registration Successful!")
      dispatch(teamRegisterSuccess(res))
      dispatch(changeRegisterProps())
    }
    catch (err) {
      toast.error("Team Registration Failed!")
      dispatch(teamRegisterFail(err))
    }
  }
}
function changeRegisterProps() {
  return {
    type: UPDATE_PROPS,
  }
}
function teamRegisterSuccess(res) {
  return {
    type: TEAM_REGISTER_SUCCESS,
    res
  }
}

function teamRegisterFail(err) {
  return {
    type: TEAM_REGISTER_ERROR,
    err
  }
}
