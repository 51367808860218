import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";

export const NavLinks = props => {
  let avatar = props.email ? props.email : null;
  if (avatar !== null) {
    avatar = avatar.charAt(0).toUpperCase();
  }
  return (
    <div>
      <ul className="fr">
        <li>
          <NavLink to="/about">About</NavLink>
        </li>
        <li>
          <NavLink to="/workshop">Workshop</NavLink>
        </li>
        <li>
          <NavLink to="/how-to-join">How to Join</NavLink>
        </li>
        <li>
          <NavLink to="/mentors">Mentors</NavLink>
        </li>
        <li>
          <NavLink to="/speakers">Judges/Speakers</NavLink>
        </li>
        <li>
          {/* <NavLink to='/finalists'>Finalists</NavLink> */}
        </li>
        <li>
          <NavLink to="/conference">Conference</NavLink>
        </li>
        <li>{/* <NavLink to='/viewer'>Participants</NavLink> */}</li>
        <li>
          <NavLink to="/prizes">Prizes</NavLink>
        </li>
        <li>
          <NavLink to="/contact-us">Contact</NavLink>
        </li>
        <li>
          <div
            className={props.email ? "circlebox" : null}
            title={props.email ? props.email : null}
          >
            {avatar}
          </div>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    email: state.auth.email
  };
};

export default withRouter(connect(mapStateToProps)(NavLinks));
