const disableWorkShop1 = (myDate) => {
  const disableDate = new Date(myDate)
  const todaysDate = Date.now()
  if (todaysDate >= disableDate) {
    return true
  }
  return false
}

const disableWorkShop2 = () => {
  const disableDate = new Date('November 15, 2019 00:00:00')
  const todaysDate = Date.now()
  if (todaysDate >= disableDate) {
    return true
  }
  return false
}

module.exports = {
  disableWorkShop1,
  disableWorkShop2
}