import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { setHeaders } from '../../shared/shared'
import { getMyDetails } from '../../shared/services'
import { Link } from 'react-router-dom'
import Proptypes from 'prop-types'
import { connect } from 'react-redux'
// import UserNavLink from './UserNavlink'
import axios from 'axios'
import { baseUrl, imageUrl } from '../../config/apiUrls'
import { getFromLocalStorage } from '../../shared/shared'
import Loader from '../layouts/loader';

export class View extends Component {
    constructor(props) {
        //console.og(props)
        super(props)
        this.state = {
            teamName: '',
            teamLeadEmail: '',
            teamLeadName: '',
            teamLeadInstitution: '',
            teamLeadDesignation: '',
            teamLeadNumber: '',
            teamMembers: [
                {
                    name: '',
                    institution: '',
                    designation: ''
                }
            ],
            projectTitle: '',
            projectBanner: null,
            projectIdea: '',
            projectTools: [
                {
                    toolName: '',
                    toolDescription: ''
                }
            ],
            videoUrl: '',
            showTeam: false,
            teamId: ''
        }
    }

    componentDidMount() {
        try {
            if (this.props.match.params.id || this.props.match.params.id !== "null") {
                //console.og(this.props)
                let userid = this.props.match.params.id
                // let userid = '5bf5033054137a0820788770'
                try {
                    axios
                        .get(`${baseUrl}listOneParticipant/${userid}`)
                        .then(response => {
                            //console.og(response[`data`])
                            this.changeMystate(response[`data`])
                        })
                        .catch(err => {
                            //console.log(err)
                        })
                } catch (err) {
                    //console.log('error fetching user details')
                }

            } else {
                //console.log('parameter id not found')
                // or get browserhistory and return to previous url
                return <Redirect to='/signin' />
            }
        } catch (err) {
            //console.log(`${err}this`)
        }
    }
    changeMystate(respo) {
        //console.og(respo)
        this.setState({
            teamId: respo._id ? respo._id : '',
            teamName: respo.teamName ? respo.teamName : '',
            teamLeadEmail: respo.teamLeadEmail ? respo.teamLeadEmail : '',
            teamLeadName: respo.teamLeadName ? respo.teamLeadName : '',
            teamLeadInstitution: respo.teamLeadInstitution
                ? respo.teamLeadInstitution
                : '',
            teamLeadDesignation: respo.teamLeadDesignation
                ? respo.teamLeadDesignation
                : '',
            teamLeadNumber: respo.teamLeadNumber ? respo.teamLeadNumber : '',
            teamMembers: respo.teamMembers ? respo.teamMembers : [],
            projectTitle: respo.projectTitle ? respo.projectTitle : '',
            projectBanner: respo.projectBanner ? respo.projectBanner : null,
            projectDescription: respo.projectDescription
                ? respo.projectDescription
                : '',
            projectIdea: respo.projectIdea ? respo.projectIdea : '',
            projectTools: respo.projectTools ? respo.projectTools : [],
            videoUrl: respo.videoUrl ? respo.videoUrl : ''
        })
        //console.og(this.state)
    }

    render() {
        //console.og()
        //console.og(this.state)
        if (this.state.teamId) {
            return (
                <div className='elseall wd-100 clearfix'>
                    <div className='container'>
                        {/* <UserNavLink /> */}
                        <div className='restbody'>
                            <div className='wd-100 clearfix'>
                                <form className='form-area-new t-a-c'>
                                    {/* if response' id(this.props.id) equals user id(caught in response) show edit button and what data comes to team view, pass to edit as props */}
                                    {/* {this.state.teamId === getFromLocalStorage('userId')
                    ? <div className='submit-box clearfix'>
                        <Link className='btn edit-btn iamnotunderlined' to={`/team/edit/${this.state.teamId}`}>
                        Edit your details
                        </Link>
                    </div>
                    : null} */}

                                    <hr />
                                    <div className='img-box'>
                                        <img
                                            src={
                                                this.state.projectBanner
                                                    ? `${imageUrl}${this.state.projectBanner}`
                                                    : '/assets/images/jpgs/team/team.jpg'
                                            }
                                            alt='sample image'
                                        />
                                    </div>
                                    <div className='text-box'>
                                        <div className='title t-b-c'>
                                            <h1>
                                                {' '}
                                                {this.state.teamName
                                                    ? this.state.teamName
                                                    : 'Team name goes here...'}
                                            </h1>
                                            <hr />
                                            <div className='wd-100 clearfix'>
                                                <div className='col col-1_2 col-md-1_1'>
                                                    <div className='padlr-15 t-a-l'>
                                                        <h4> Team Leader </h4>
                                                        <div className='clearfix'>
                                                            <h5>
                                                                {' '}
                                                                {this.state.teamLeadName
                                                                    ? this.state.teamLeadName
                                                                    : 'Team Lead Name goes here...'}
                                                                {' '}
                                                            </h5>
                                                            {/* <input type="text" className="iameditable" placeholder="Name" onChange={this.handleChange} /> */}
                                                            <h6>
                                                                {' '}
                                                                {this.state.teamLeadNumber
                                                                    ? this.state.teamLeadNumber
                                                                    : 'Team lead Number'}
                                                                {' '}
                                                            </h6>
                                                            {/* <input type="number" className="iameditable" maxLength="10" placeholder="Phone Number" onChange={this.handleChange} /> */}
                                                            <label>
                                                                {' '}
                                                                {this.state.teamLeadEmail
                                                                    ? this.state.teamLeadEmail
                                                                    : 'Team Lead Email'}
                                                                {' '}
                                                            </label>
                                                            {/* <input type="email" className="iameditable" placeholder="Email Address" onChange={this.handleChange} /> */}
                                                            <label>
                                                                {' '}
                                                                {this.state.teamLeadDesignation
                                                                    ? this.state.teamLeadDesignation
                                                                    : 'Designation'}
                                                                {' '}
                                                            </label>
                                                            {/* <input type="text" className="iameditable" placeholder="Post" onChange={this.handleChange} /> */}
                                                            <label>
                                                                {this.state.teamLeadInstitution
                                                                    ? this.state.teamLeadInstitution
                                                                    : 'Institution'}
                                                                {' '}
                                                            </label>
                                                            {/* <input type="text" className="iameditable" placeholder="Associated  place" onChange={this.handleChange} /> */}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col col-1_2 col-md-1_1'>
                                                    <div className='padlr-15 t-a-l'>
                                                        <h4> Team Members </h4>
                                                        {this.state.teamMembers
                                                            ? /* if(this.state.team.team.teamMembers){} */
                                                            this.state.teamMembers.map((member, index) => (
                                                                <div className='wd-100 clearfix' key={index}>
                                                                    <h5>
                                                                        {' '}{member.name}{' '}
                                                                    </h5>
                                                                    {/* <input type="text" className="iameditable" placeholder="Name" /> */}
                                                                    <label>
                                                                        {' '}{member.designation}{' '}
                                                                    </label>
                                                                    {/* <input type="email" className="iameditable" placeholder="Post" /> */}
                                                                    <label>
                                                                        {' '}{member.institution}{' '}
                                                                    </label>
                                                                    {/* <input type="email" className="iameditable" placeholder="Associated  place" /> */}
                                                                    <br />
                                                                </div>
                                                            ))
                                                            : 'Team members are listed here.'}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="wd-100 clearfix">
                                                <div className='col col-1_1'>
                                                    <div className='t-a-l'>
                                                        {/* <label className='primary'> Youtube URL</label> */}
                                                        <a href={this.state.videoUrl
                                                            ? `https://${this.state.videoUrl}`
                                                            : null} className="youtubelink" target="_blank">
                                                            {' '}
                                                            {this.state.videoUrl
                                                                ? this.state.videoUrl
                                                                : 'No Video Url Provided'}
                                                            {' '}
                                                        </a>
                                                    </div>

                                                </div>
                                            </div>
                                            <hr />
                                            <div className="wd-100 clearfix">
                                                <div className='col col-1_1'>
                                                    <h2> About Our Project </h2>
                                                    <br />
                                                    <div className='t-a-l'>
                                                        <label className='primary'> Project Title </label>
                                                        <h4>
                                                            {this.state.projectTitle
                                                                ? this.state.projectTitle
                                                                : 'Project title'}
                                                        </h4>
                                                        {/* <input type="text" className="iameditable" placeholder="Project Title" onChange={this.handleChange} /> */}
                                                        <label className='primary'>
                                                            {' '}Project Description{' '}
                                                        </label>
                                                        <p>
                                                            {this.state.projectDescription
                                                                ? this.state.projectDescription
                                                                : 'Project Description'}
                                                        </p>
                                                        {/* <textarea className="iameditable" rows="10" onChange={this.handleChange} ></textarea> */}
                                                        <label className='primary'>
                                                            {' '}Idea behind this Project{' '}
                                                        </label>
                                                        <p>
                                                            {this.state.projectIdea
                                                                ? this.state.projectIdea
                                                                : ' Idea behind this project'}
                                                        </p>
                                                        {/* <textarea className="iameditable" rows="10" onChange={this.handleChange} ></textarea> */}
                                                        <label className='primary'>
                                                            {' '}Tools for this Project{' '}
                                                        </label>
                                                        {this.state.projectTools
                                                            ? this.state.projectTools.map((tool, index) => {
                                                                return (
                                                                    <div className='toolbox' key={index}>
                                                                        {/* <input value={tool.title} type="text" placeholder="tool name (For eg: node.js)" /> */}
                                                                        <label>{tool.toolName}</label>

                                                                        <p>
                                                                            {tool.toolDescription}
                                                                        </p>

                                                                    </div>
                                                                )
                                                            })
                                                            : 'Tools used for this project'}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return <div>Loading...</div>
        }
    }
}

const mapStateToProps = state => {
    return {
        id: state.auth.userId
    }
}
export default connect(mapStateToProps, null)(View)
