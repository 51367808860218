import React, { Component } from "react";
// import './App.css'
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

import Navbar from "./components/layouts/Navbar";
import MainFooter from "./components/Footer/MainFooter";
import Snackbar from "./components/layouts/snackbar.js";
import HomePage from "./components/layouts/HomePage";
import NotFound from "./components/layouts/NotFound";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import MentorsPage from "./components/layouts/MentorsPage";
import FinalistsPage from "./components/layouts/Finalistspage";
import SpeakersPage from "./components/layouts/SpeakersPage";
import PrizesPage from "./components/layouts/PrizesPage";
import GetInTouch from "./components/layouts/GetInTouch";
import SpirathonHistory from "./components/layouts/About";
import WorkShop from "./components/layouts/WorkshopPage";
import Steps from "./components/layouts/StepsPage";
import Dashboard from "./components/user/Dashboard.js";
import TeamView from "./components/user/TeamView.js";
import Opponent from "./components/user/Opponent.js";
import Rules from "./components/user/Rules.js";
// import ComingSoon from './components/layouts/ComingSoon'
import View from "./components/layouts/View";
import TeamEdit from "./components/user/TeamEdit";
import ViewerPage from "./components/layouts/Viewerpage";
import Loader from "./components/layouts/loader";
import Ticketing from "./components/layouts/Ticketpage";
import Conference from "./components/layouts/Conferencepage";
import UserNavLink from "./components/user/UserNavlink";
import NotificationComponent from "./components/layouts/Notification";
import ReactGA from "react-ga";

class App extends Component {
  componentDidMount() {
    this.initializeReactGA();
  }

  initializeReactGA() {
    ReactGA.initialize("UA-130301796-1");
    ReactGA.pageview("/viewer");
  }

  render() {
    return (
      <BrowserRouter>
        <React.Fragment>
          <Navbar />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/signin" component={Login} />
            <Route path="/signup" component={Register} />
            <Route path="/mentors" component={MentorsPage} />
            <Route path="/finalists" component={FinalistsPage} />
            <Route path="/speakers" component={SpeakersPage} />
            <Route path="/prizes" component={PrizesPage} />
            <Route path="/workshop" component={WorkShop} />
            <Route path="/about" component={SpirathonHistory} />
            <Route path="/how-to-join" component={Steps} />
            <Route path="/contact-us" component={GetInTouch} />
            <Route path="/not-found" component={NotFound} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/opponents" component={Opponent} />
            <Route path="/viewer" component={ViewerPage} />
            <Route exact path="/team/edit/:id" component={TeamEdit} />
            <Route path="/team-view/:id" component={TeamView} />
            <Route path="/details/:id" component={View} />
            <Route path="/rules" component={Rules} />
            <Route path="/know-your-opponents" component={Opponent} />
            <Route path="/ticketing/:id" component={Ticketing} />
            <Route path="/loader" component={Loader} />
            <Route path="/conference" component={Conference} />
            <Redirect from="*" to="/not-found" />
          </Switch>
          <MainFooter />
          {/* <Snackbar /> */}
          <NotificationComponent />
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

export default App;
