import React from 'react'
import { NavLink } from 'react-router-dom'

export const SignedOutFooter = () => {
  return (
    <div className='col col-1-4 clearfix fr'>
      <div className='footer-btn'>
        {/* <NavLink to='/signup' className='btn register-btn'> */}
        <a href  =  "https://docs.google.com/forms/d/e/1FAIpQLSemgKJQ3KhEHXOMuiD4Dg0bIQdSTgCfjfxBi0aR1NvxaHjYoQ/viewform" target = "_blank" className='btn register-btn'>
          {/* <img
            src='/assets/images/icons/register.png'
            alt='registration button'
          /> */}
          <label>
            Register For Event
          </label>
          </a>
        {/* </NavLink> */}
        <NavLink to='/signin' className='btn login-btn'>
          {/* <img src='/assets/images/icons/login.png' alt='login button' /> */}
          <label>
            Login
          </label>
        </NavLink>
      </div>
    </div>
  )
}

export default SignedOutFooter
