import React, { Component } from 'react'
// import RenderCircularImage from './imageRenderCircle'
import axios from 'axios'
import TextRender from './renderText';
const parseString = require('xml2js').parseString

class MentorsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Mentors: [],
    }
  }

  componentDidMount() {
    axios.get(`xmls/mentors.xml`).then(response => {
      parseString(response.data, (error, result) => {
        if (error) {
          //console.log('error')
        } else {
          this.setState({
            Mentors: [...result['Spirathon']['Mentors'][0]['Row']],
          })
        }
      })
    })
  }

  renderThree(items) {
    return items.map((item, index) => (
      <div className='col col-1_3 col-sm-1_1' key={index}>
        <div className='textbox'>
          <div className='thumbnail-box2'>
            <img
              src={item.image[0].src[0]}
              alt={item.image[0].title[0]}
            />
          </div>
          <h2>
            <TextRender text={item.Name[0]} />
          </h2>
          <h6>
            <TextRender text={item.Institution[0]} />
          </h6>
        </div>
      </div>
    ))
  }

  renderTwo(items) {
    return items.map((item, index) => (
      <div className='col col-1_2 col-sm-1_1' key={index}>
        <div className='textbox'>
          <div className='thumbnail-box2'>
            <img
              src={item.image[0].src[0]}
              alt={item.image[0].title[0]}
            />
          </div>
          <h2>
            <TextRender text={item.Name[0]} />
          </h2>
          <h6>
            <TextRender text={item.Institution[0]} />
          </h6>
        </div>
      </div>
    ))
  }

  render() {
    return (
      <main>
        <div className='elseall clearfix'>
          <div className='container'>
            {/* <h1 className="t-a-c cullprit">Updating soon</h1> */}
            <div className='topp wd-100 clearfix'>
              <div className='col col-3_5 col-sm-1_1'>
                <h1>Refine your ideas with our Mentors. </h1>
                <div className='col col-1_3 col-sm-1_1'>
                  <label>
                    Each team will be assigned a mentor to guide through their project. The mentor will only give suggestions and will NOT be doing the actual coding part for the team.
                  </label>
                </div>
              </div>
              <div className='col col-2_5 col-sm-1_1'>
                <div className='thumbnail-box2 hide-sm'>
                  <img
                    src='/assets/images/graphics/goodhands-02.png'
                    alt=''
                  />
                </div>
              </div>
            </div>
            {
              this.state.Mentors.map((item, index) => {
                //console.og(item)
                if (item['$']['id'] % 2 === 1) {
                  return (
                    <div className='wd-100 clearfix prize' key={index}>
                      {this.renderThree(item['Description'])}
                    </div>
                  )
                }
                else {
                  return (
                    <div className='wd-100 clearfix prize' key={index}>
                      {this.renderTwo(item['Description'])}
                    </div>
                  )
                }

              })
            }
          </div>
        </div>
      </main>
    )
  }
}

export default MentorsPage

