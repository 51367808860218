import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// import RenderCircularImage from './imageRenderCircle'
import { disableWorkShop1 } from "../../shared/workshopDisable";
import axios from "axios";
import TextRender from "./renderText";
const parseString = require("xml2js").parseString;

class ViewerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workshop: {}
    };
  }
  componentDidMount() {
    if (this.props.match.params.id) {
      try {
        axios.get(`/xmls/workshop.xml`).then(response => {
          parseString(response.data, (error, result) => {
            if (error) {
              //console.log('error')
            } else {
              const workshops =
                result["Spirathon"]["Workshop"][0]["Description"];
              console.log(result["Spirathon"]["Workshop"][0]["Description"]);
              const workshop = workshops.find((item, index) => {
                return item[`Title`][0] === this.props.match.params.id;
              });
              this.setState({ workshop });
            }
          });
        });
      } catch (error) {}
    } else {
      return <Redirect to="signin" />;
    }
  }
  callDisabled = () => {
    const dDate = this.state.workshop.DisableDate[0];
    disableWorkShop1(dDate);
  };

  render() {
    if (this.state.workshop === {}) {
      return <div>Loading</div>;
    } else {
      const dDate = this.state.workshop.DisableDate
        ? this.state.workshop.DisableDate[0]
        : null;
      const linkurl = this.state.workshop.LinkUrl
        ? this.state.workshop.LinkUrl[0]
        : "";
      //console.og(dDate)
      const isDisabled = this.state.workshop.DisableDate
        ? disableWorkShop1(dDate)
        : false;
      return (
        <main>
          <div className="elseall clearfix">
            <div className="container">
              {/* <h1 className="t-a-c cullprit">Updating soon</h1> */}
              <div className="wd-100 clearfix">
                <div className="form-area-new t-a-c ws-touch">
                  <div className="img-box">
                    <img
                      src="/assets/images/jpgs/workshop/image-01.png"
                      alt="sample image"
                    />
                  </div>
                  <div className="text-box">
                    <div className="title t-b-c">
                      <h2>
                        {" "}
                        {this.state.workshop.Title
                          ? this.state.workshop.Title[0]
                          : null}
                      </h2>
                      <hr />

                      <div className="col col-1_1">
                        <h4>
                          {" "}
                          {this.state.workshop.Speaker
                            ? this.state.workshop.Speaker[0].Name[0]
                            : null}{" "}
                        </h4>
                        <h6>
                          {" "}
                          {this.state.workshop.Speaker
                            ? this.state.workshop.Speaker[0].Institution[0]
                            : null}{" "}
                        </h6>
                        <br />
                        <div className="makemedark">
                          <label>
                            {this.state.workshop.Venue ? "Venue" : null}:{" "}
                            <span>
                              {this.state.workshop.Venue
                                ? this.state.workshop.Venue[0]
                                : null}
                            </span>{" "}
                          </label>
                          <label>
                            {this.state.workshop.EventDate ? "Date" : null}:{" "}
                            <span>
                              {this.state.workshop.EventDate
                                ? this.state.workshop.EventDate[0]
                                : null}
                            </span>{" "}
                          </label>
                          <label>
                            Event :{" "}
                            <span>
                              {this.state.workshop.EventTime
                                ? this.state.workshop.EventTime[0]
                                : null}
                            </span>{" "}
                          </label>
                          <label>
                            {this.state.workshop.Registration
                              ? "Event Registration start:"
                              : null}{" "}
                            <span>
                              {this.state.workshop.Registration
                                ? this.state.workshop.Registration[0]
                                : null}
                            </span>{" "}
                          </label>
                        </div>
                        <div className="t-a-l">
                          <label className="primary">
                            {" "}
                            {this.state.workshop.Requirements
                              ? "Workshop Requirements"
                              : null}{" "}
                          </label>
                          <ol>
                            {this.state.workshop.Requirements
                              ? this.state.workshop.Requirements.map(
                                  (item, index) => {
                                    return <li key={index}>{item}</li>;
                                  }
                                )
                              : null}
                          </ol>

                          <label className="primary">
                            {this.state.workshop.Speaker
                              ? this.state.workshop.Speaker[0]
                                ? this.state.workshop.Speaker[0].Intro
                                  ? "About the Speaker"
                                  : null
                                : null
                              : null}
                          </label>
                          <p>
                            {this.state.workshop.Speaker
                              ? this.state.workshop.Speaker[0]
                                ? this.state.workshop.Speaker[0].Intro
                                  ? this.state.workshop.Speaker[0].Intro[0]
                                  : null
                                : null
                              : null}
                          </p>
                          <label className="primary">
                            {this.state.workshop.About
                              ? " About This Workshop "
                              : null}
                          </label>
                          <p>
                            {this.state.workshop.About
                              ? this.state.workshop.About[0]
                              : null}
                          </p>
                          {/* <label className="primary"> {this.state.workshop.Why ? 'Why this project?' : null} </label> */}
                          <p>
                            {this.state.workshop.Why
                              ? this.state.workshop.Why[0]
                              : null}
                          </p>
                          {/* <label className="primary"> Some other title </label> */}
                        </div>
                        <div className="redticket" hidden={isDisabled}>
                          <label>
                            Book this ticket now before the seats are full.
                            Remember your seat is not booked until you receive
                            confirmation email.
                          </label>
                          <button
                            disabled={isDisabled}
                            className="btn register-btn"
                          >
                            <a
                              href={
                                isDisabled ? "javascript:void(0);" : linkurl
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Book Now
                            </a>
                          </button>
                        </div>
                        <div className="redticket" hidden={!isDisabled}>
                          <label>Read our blog about this workshop</label>
                          {this.state.workshop.BlogUrl
                            ? this.state.workshop.BlogUrl.map((item, index) => (
                                <button
                                  disabled={!isDisabled}
                                  className="btn register-btn"
                                  key={index}
                                >
                                  <a
                                    href={
                                      !isDisabled ? "javascript:void(0);" : item
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Blog {index + 1}
                                  </a>
                                </button>
                              ))
                            : null}
                        </div>
                        <div className="redticket" hidden={!isDisabled}>
                          <label>
                            Catch the slides of this workshop from down below.
                          </label>
                          {this.state.workshop.SlidesUrl
                            ? this.state.workshop.SlidesUrl.map(
                                (item, index) => (
                                  <button
                                    disabled={!isDisabled}
                                    className="btn register-btn"
                                    key={index}
                                  >
                                    <a
                                      href={
                                        !isDisabled
                                          ? "javascript:void(0);"
                                          : item
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Slide {index + 1}
                                    </a>
                                  </button>
                                )
                              )
                            : null}
                        </div>
                        <div className="redticket" hidden={!isDisabled}>
                          <label>
                            Browse the Github, for this workshop content.
                          </label>
                          {this.state.workshop.GitUrl
                            ? this.state.workshop.GitUrl.map((item, index) => (
                                <button
                                  disabled={!isDisabled}
                                  className="btn register-btn"
                                  key={index}
                                >
                                  <a
                                    href={
                                      !isDisabled ? "javascript:void(0);" : item
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Github Link {index + 1}
                                  </a>
                                </button>
                              ))
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    }
  }
}

export default ViewerPage;
