import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Proptypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'
import { teamRegister } from '../../store/actions/userActions'
import UserNavLink from './UserNavlink'
import { setHeaders } from '../../shared/shared'
import { baseUrl } from '../../config/apiUrls'
import { getFromLocalStorage } from '../../shared/shared'
import { Redirect } from 'react-router-dom'
import { Loader } from '../layouts/loader'
import { toast } from 'react-toastify'

class TeamEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      teamName: '',
      teamLeadEmail: '',
      teamLeadName: '',
      teamLeadInstitution: '',
      teamLeadDesignation: '',
      teamLeadNumber: '',
      teamMembers: [
        {
          name: '',
          institution: '',
          designation: '',
        }
      ],
      projectTitle: '',
      projectBanner: '',
      bannerFile: '',
      tempBannerImage: '',
      projectIdea: '',
      projectDescription: '',
      projectTools: [
        {
          toolName: '',
          toolDescription: ''
        }
      ],
      videoUrl: '',
      showTeam: false,
      isTeamRegistered: false
    }
  }

  componentDidMount() {
    //console.og(this.props.match.params.id)
    try {
      if (this.props.match.params.id || this.props.match.params.id !== "null") {
        let userid = this.props.match.params.id
        // let userid = '5bf5033054137a0820788770'
        try {
          axios
            .get(`${baseUrl}v1/users/${userid}`, { headers: setHeaders() })
            .then(response => {
              //console.og(response[`data`])
              this.changeMystate(response[`data`])
            })
            .catch(err => {
              //console.log(err)
            })
        } catch (err) {
          //console.log('error fetching user details')
        }
      } else if (getFromLocalStorage('userId')) {
        let userid = getFromLocalStorage('userId')
        try {
          axios
            .get(`${baseUrl}v1/users/${userid}`, { headers: setHeaders() })
            .then(response => {
              //console.og(response[`data`])
              this.changeMystate(response[`data`])
            })
            .catch(err => {
              //console.log(err)
            })
        } catch (err) {
          //console.log('error fetching user details')
        }
      } else {
        //console.log('parameter id not found')
        // or get browserhistory and return to previous url
        return <Redirect to='/signin' />
      }
    } catch (err) {
      //console.log(`${err}this`)
    }
  }

  changeMystate(respo) {

    //look at error that browser is now showing
    //set members array max length to 3.
    //add edit button to only own details page not others
    //store image name in state to store in db
    //bind data in teamview
    //cancel button restores the state,(maybe use redux state to rehydrate)
    //alert buttons, validators in fields, change server mail message,
    //change api of participants page to api that runs without token
    this.setState({
      teamName: respo.teamName ? respo.teamName : '',
      teamLeadEmail: respo.teamLeadEmail ? respo.teamLeadEmail : '',
      teamLeadName: respo.teamLeadName ? respo.teamLeadName : '',
      teamLeadInstitution: respo.teamLeadInstitution ? respo.teamLeadInstitution : '',
      teamLeadDesignation: respo.teamLeadDesignation ? respo.teamLeadDesignation : '',
      teamLeadNumber: respo.teamLeadNumber ? respo.teamLeadNumber : '',
      teamMembers: respo.teamMembers.length !== 0 ? respo.teamMembers : [{
        name: '',
        institution: '',
        designation: '',
      }],
      projectTitle: respo.projectTitle ? respo.projectTitle : '',
      projectBanner: respo.projectBanner ? respo.projectBanner : '',
      projectDescription: respo.projectDescription ? respo.projectDescription : '',
      projectIdea: respo.projectIdea ? respo.projectIdea : '',
      projectTools: respo.projectTools.length !== 0 ? respo.projectTools : [{
        toolName: '',
        toolDescription: ''
      }],
      videoUrl: respo.videoUrl ? respo.videoUrl : '',
    })
  }

  addTeamMembers(e) {
    this.setState({
      teamMembers: [...this.state.teamMembers, {}]
    })
  }
  addTools(e) {
    this.setState({
      projectTools: [...this.state.projectTools, {}]
    })
  }

  handleRemoveMembers(index) {
    let arr = this.state.teamMembers
    arr.splice(index, 1)
    this.setState({ teamMembers: arr })
  }

  handleRemoveTools(index) {
    let arr = this.state.projectTools
    arr.splice(index, 1)
    this.setState({ projectTools: arr })
  }

  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value })
    //console.og(this.state)
  }

  //handle changes in dynamic input forms, need to make it dynamic

  handleNameChange(e, index) {
    this.state.teamMembers[index].name = e.target.value
    this.setState({ teamMembers: this.state.teamMembers })
  }

  handleDesignationChange(e, index) {
    this.state.teamMembers[index].designation = e.target.value
    this.setState({ teamMembers: this.state.teamMembers })
  }

  handleInstitutionChange(e, index) {
    this.state.teamMembers[index].institution = e.target.value
    this.setState({ teamMembers: this.state.teamMembers })
  }

  handleToolChange(e, index) {
    this.state.projectTools[index].toolName = e.target.value
    this.setState({ projectTools: this.state.projectTools })
  }

  handleDescriptionChange(e, index) {
    this.state.projectTools[index].toolDescription = e.target.value
    this.setState({ projectTools: this.state.projectTools })
  }

  handleSubmit(e, userId) {
    e.preventDefault()
    // call actions on userAction
    this.setState({
      isTeamRegistered: true,
      tempBannerImage: undefined,
      bannerFile: undefined
    }, () => {
      //console.og(this.state)
      this.props.registerTeam(this.props.id, this.state)
      this.props.history.push(`/team-view/${userId}`)
    })
  }

  fileChangedHandler = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        tempBannerImage: reader.result,
        bannerFile: file
      })
    }
    reader.readAsDataURL(file)
    //console.og(event.target.files[0])
    const formData = new FormData();
    formData.append('banner', event.target.files[0]);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'x-access-token': localStorage.getItem('token')
      }
    };
    try {
      axios
        .post(`${baseUrl}v1/uploadBanner`, formData, config)
        .then(response => {
          //console.og(response)
          if (response.data.success = true) {
            //console.og(response[`data`])
            this.callSetSate(response)
            toast.success('Image Upload Successful')
            //console.og(this.state)
          }
          else {
            toast.error(response.data.message)
          }

        })
        .catch(err => {
          toast.error('Image Upload Failed')
          //console.log(err)
        })
    } catch (err) {
      //console.log('error fetching users list')
    }

    //files are uploaded to this url -http://localhost:2000/v1/uploadBanner

  }
  callSetSate = (response) => {
    this.setState({
      projectBanner: response[`data`][`filename`]
    })
  }

  changeMyImageState = (respo) => {
    //console.og(respo)
    this.setState({
      projectBanner: respo
    })
    //console.og(respo)
  }

  render() {
    const userId = getFromLocalStorage('userId')
    let { tempBannerImage } = this.state;
    let imagePreview = null;
    if (tempBannerImage) {
      imagePreview = (<img src={tempBannerImage} />);
    } else {
      imagePreview = (<div>Please select an Image for Preview</div>);
    }
    if (1) {
      return (
        <div className='elseall wd-100 clearfix'>
          <div className='container'>
            <UserNavLink />
            <div className='restbody'>
              <div className='wd-100 clearfix'>
                <form
                  className='form-area-new t-a-c'
                >
                  <div className='submit-box clearfix'>
                    <Link className='btn dom-btn iamnotunderlined' to={`/team-view/${userId}`} id='cancel-btn'>
                      Cancel
                      </Link>
                    <button type='submit' onClick={(e) => this.handleSubmit(e, userId)} className='btn dom-btn' id='save-btn'>
                      Save
                    </button>
                  </div>
                  <hr />
                  <div className='img-box'>

                    {/* <img src="/assets/images/jpgs/about/image-01.jpg" alt="sample image" className="iamfixed" /> */}
                    <h6 >
                      Please enter a Cover Photo, A cover photo should contain all the team members.
                      Failure to comply this requirement will result in disqualification.
                  </h6>
                    <input
                      id="banner"
                      type='file'
                      placeholder='Team banner'
                      onChange={this.fileChangedHandler}
                      required
                    />
                  </div>
                  <div className='img-box'>
                    {imagePreview}

                  </div>
                  <div className='text-box'>
                    <div className='title t-b-c'>
                      {/* <h1 className="iamfixed"> {this.state.teamName}</h1> */}
                      <h3 htmlFor='Team name'>
                        Write your Team Name :{' '}
                      </h3>
                      <input
                        id="teamName"
                        type='text'
                        placeholder='Team Name'
                        value={this.state.teamName}
                        onChange={this.handleChange}
                        required
                      />
                      <hr />
                      <div className='wd-100 clearfix'>
                        <div className='col col-1_2 col-md-1_1'>
                          <div className='padlr-15 t-a-l'>
                            <h4> Team Leader </h4>
                            <div className='clearfix'>
                              {/* <h5 className="iamfixed"> {this.state.teamLeadName} </h5> */}
                              <input
                                id="teamLeadName"
                                type='text'
                                placeholder='Name'
                                value={this.state.teamLeadName}
                                onChange={this.handleChange}
                                required
                              />
                              {/* <h6 className="iamfixed"> {this.state.teamLeadNum} </h6> */}
                              <input
                                id="teamLeadNumber"
                                type='tel'
                                maxLength='10'
                                placeholder='Phone Number'
                                value={this.state.teamLeadNumber}
                                onChange={this.handleChange}
                                required
                              />
                              {/* <label className="iamfixed"> {this.state.teamLeadEmail} </label> */}
                              <input
                                id="teamLeadEmail"
                                type='email'
                                placeholder='Email Address'
                                value={this.state.teamLeadEmail}
                                onChange={this.handleChange}
                                required
                              />
                              {/* <label className="iamfixed"> {this.state.designation} </label> */}
                              <input
                                id="teamLeadDesignation"
                                type='text'
                                placeholder='Post'
                                value={this.state.teamLeadDesignation}
                                onChange={this.handleChange}
                                required
                              />
                              {/* <label className="iamfixed">{this.state.Institute} </label> */}
                              <input
                                id="teamLeadInstitution"
                                type='text'
                                placeholder='Associated Institution'
                                value={this.state.teamLeadInstitution}
                                onChange={this.handleChange}
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className='col col-1_2 col-md-1_1'>
                          <div className='padlr-15 t-a-l'>
                            <h4> Team Members </h4>
                            {this.state.teamMembers.length > 0 ? this.state.teamMembers.map((member, index) => (
                              <div className='wd-100 clearfix' key={index}>
                                <h5 > Name </h5>
                                <input
                                  type='text'
                                  placeholder='Name'
                                  value={member.name}
                                  onChange={(e) => this.handleNameChange(e, index)}
                                  required
                                />
                                <label>
                                  Designation
                                </label>
                                <input
                                  type='text'
                                  placeholder='Post'
                                  value={member.designation}
                                  onChange={(e) => this.handleDesignationChange(e, index)}
                                  required
                                />
                                <label >
                                  Institution
                                </label>
                                <input
                                  type='text'
                                  placeholder='Associated Institution'
                                  value={member.institution}
                                  onChange={(e) => this.handleInstitutionChange(e, index)}
                                  required
                                />
                                <div className='btn-box fr'>
                                  {index === 0 ? null :
                                    <button type='button'
                                      className='btn-2 btn-close fr tooltip'
                                      onClick={(e) => this.handleRemoveMembers(index)}
                                    >
                                      <span className='tooltiptext'>
                                        Delete member
                                      </span>
                                      <img
                                        src='/assets/images/icons/close.png'
                                        alt='close me'
                                      />
                                    </button>
                                  }
                                </div>
                                <br />
                              </div>
                            )) : 'no input field'}
                            <div className="wd-100 putmeoncenter">
                              {this.state.teamMembers.length === 2 ? null :
                                <button type='button'
                                  className='btn-2 btn-add fr tooltip'
                                  onClick={(e) => this.addTeamMembers(e)}
                                >
                                  <span className='tooltiptext'>
                                    Add new member
                                          </span>
                                  <img
                                    src='/assets/images/icons/close.png'
                                    alt='close me'
                                  />
                                </button>
                              }
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className='col col-1_1'>
                          <div className='t-a-l'>
                            <label className='primary'> Enter youtube URL of your Introduction Video </label>
                            {/* <h4 className="iamfixed">{this.state.projectTitle}</h4> */}

                            <input type="url" name="url" id="videoUrl"
                              placeholder="Youtube Link"
                              pattern="https://.*" size="30"
                              value={this.state.videoUrl}
                              onChange={this.handleChange}
                              required />
                          </div>
                        </div>
                        <hr />
                        <div className='col col-1_1'>
                          <h2> About Our Project </h2>
                          <br />
                          <div className='t-a-l'>
                            <label className='primary'> Project Title </label>
                            {/* <h4 className="iamfixed">{this.state.projectTitle}</h4> */}
                            <input
                              id="projectTitle"
                              type='text'
                              placeholder='Project Title'
                              value={this.state.projectTitle}
                              onChange={this.handleChange}
                              required
                            />
                            <label className='primary'>
                              {' '}Project Description{' '}
                            </label>
                            {/* <p className="iamfixed">{this.state.projectDesc}</p> */}
                            <textarea
                              id="projectDescription"
                              rows='10'
                              value={this.state.projectDescription}
                              onChange={this.handleChange}
                              required
                            />
                            <label className='primary'>
                              {' '}Idea behind this Project{' '}
                            </label>
                            {/* <p className="iamfixed">{this.state.projectIdea}</p> */}
                            <textarea
                              id="projectIdea"
                              rows='10'
                              value={this.state.projectIdea}
                              onChange={this.handleChange}
                              required
                            />
                            <label className='primary'>
                              {' '}Tools for this Project{' '}
                            </label>
                            {this.state.projectTools.length > 0 ? this.state.projectTools.map(
                              (tool, index) => {
                                return (
                                  <div
                                    key={index}
                                  >
                                    <input
                                      value={tool.toolName}
                                      onChange={(e) => this.handleToolChange(e, index)}
                                      type='text'
                                      placeholder='tool name (For eg: node.js)'
                                      required
                                    />
                                    <label>Why this tool ?</label>
                                    <textarea
                                      value={tool.toolDescription}
                                      onChange={(e) => this.handleDescriptionChange(e, index)}
                                      name='descriptions'
                                      id=''
                                      rows='5'
                                      required
                                    />
                                    <div className='btn-box fr'>
                                      {index === 0 ? null :
                                        <button type='button'
                                          className='btn-2 btn-close fr tooltip'
                                          onClick={(e) => this.handleRemoveTools(index)}
                                        >
                                          <span className='tooltiptext'>
                                            Delete this tool
                                          </span>
                                          <img
                                            src='/assets/images/icons/close.png'
                                            alt='close me'
                                          />
                                        </button>
                                      }
                                    </div>
                                  </div>
                                )
                              }
                            ) : 'no input field'}
                            <div className="putmeoncenter">
                              <button type='button'
                                className='btn-2 btn-add fr tooltip'
                                onClick={(e) => this.addTools(e)}
                              >
                                <span className='tooltiptext'>
                                  Add new tool
                                      </span>
                                <img
                                  src='/assets/images/icons/close.png'
                                  alt='close me'
                                />
                              </button>
                            </div>

                            {/* <div className="toolbox iameditable">
                            <hr className="hr" />
                            <input type="text" placeholder="tool name (For eg: node.js)" />
                            <label>Why this tool ?</label>
                            <textarea name="descriptions" id="" rows="5"></textarea>
                            <div className="btn-box fr">
                              <button className="btn-2 btn-add fr tooltip">
                                <span className="tooltiptext">Add new tool</span>
                                <img src="/assets/images/icons/close.png" alt="close me" />
                              </button>
                              <button className="btn-2 btn-close fr tooltip">
                                <span className="tooltiptext">Delete this tool</span>
                                <img src="/assets/images/icons/close.png" alt="close me" />
                              </button>
                            </div>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className='submit-box clearfix'>
                    <Link className='btn dom-btn iamnotunderlined' to={`/team-view/${userId}`} id='cancel-btn'>
                      Cancel
                      </Link>
                    <button type='submit' onClick={(e) => this.handleSubmit(e, userId)} className='btn dom-btn' id='save-btn'>
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )
    }
    else {
      return (
        <Loader></Loader>
      )
    }
  }
}


// TeamEdit.proptypes = {
//   registerTeam: Proptypes.func.isRequired
// }
const mapStateToProps = state => {
  return {
    email: state.auth.email,
    isLoggedIn: state.auth.isLoggedIn,
    id: state.auth.userId,
    team: state.team.team
  }
}

const mapDispatchToProps = dispatch => {
  return {
    registerTeam: (id, teamInfo) => {
      dispatch(teamRegister(id, teamInfo))
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TeamEdit)
