import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'
import { connect } from 'react-redux'
import { baseUrl, imageUrl } from '../../config/apiUrls'
import { setHeaders } from '../../shared/shared'
import UserNavLink from './UserNavlink'
import Loader from '../layouts/loader';

class Opponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      opponentList: [],
    }
  }

  componentDidMount() {
    try {
      axios
        .get(`${baseUrl}v1/users`, { headers: setHeaders() })
        .then(response => {
          //console.og(response)
          if (response && response.data) {
            // set state here
            this.sortOpponentList(response.data)
          }
        })
        .catch(err => {
          //console.log(err)
        })
    } catch (err) {
      //console.log('error fetching users list')
    }
  }

  sortOpponentList(list) {
    const email = localStorage.getItem('email')
    let sortedList = list.length > 0 ? list.filter(el => el.email !== email) : list
    this.setState({ opponentList: sortedList })
  }

  render() {
    if (!this.props.isLoggedIn) return <Redirect to='/signin' />
    if (this.state.opponentList && this.state.opponentList.length > 0) {
      return (
        <div className='elseall wd-100 clearfix'>
          <div className='container'>
            <UserNavLink />
            <div className='restbody'>
              <div className="wd-100 clearfix">
                {this.state.opponentList.map((eachTeam, index) => {
                  //console.og(eachTeam)
                  if (eachTeam && eachTeam.isTeamRegistered === true) {
                    return (
                      <div className="col col-1_3 col-sm-1_1" key={index}>
                        <div className="padlr-15">
                          <div className="oppo-box">
                            <div className="img-box">
                              <img src={eachTeam.projectBanner ? imageUrl + eachTeam.projectBanner : "/assets/images/jpgs/team/team.jpg"} alt="team image here" />
                            </div>
                            <div className="text-box">
                              <h4>
                                Team Name: <span>{eachTeam.teamName}</span>
                              </h4>
                              <h5>
                                Project Name: <span>{eachTeam.projectTitle}</span>
                              </h5>
                              <Link to={`/team-view/${eachTeam._id}`}>View this Project</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  } else {
                    return (
                      <div key={index}>

                      </div>
                    )
                  }
                })
                }
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (<Loader></Loader>)
    }

  }
}
const mapStateToProps = state => {
  return {
    isLoggedIn: state.auth.isLoggedIn
  }
}
export default connect(mapStateToProps, null)(Opponent)
