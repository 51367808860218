import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { getFromLocalStorage } from "../../shared/shared";

class NotRegisteredNavLink extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let id = getFromLocalStorage("userId");
    return (
      <div className="dashnav">
        <ul className="bottomnav">
          <li>
            <NavLink to="/rules">Rules</NavLink>
            {/* <NavLink to='/opponents'>Opponents</NavLink> */}
            <NavLink to={`/team/edit/${id}`}>Register</NavLink>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isRegistered: state.auth.registered
  };
};

export default connect(mapStateToProps)(NotRegisteredNavLink);
