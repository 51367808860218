import React from 'react'

const GetInTouch = () => {
  return (
    <div className="elseall clearfix">
      <div className="container">
        <div className="contact-box wd-100 clearfix">
          <h1>Get in touch with us</h1>
          {/* <p>This is the slogan thatneeds to be filled.</p> */}
          <div className='contact-us clearfix'>
            <div className="bg-overlap">
              <img src="/assets/images/logo/mainlogo.png" alt='spiralogics-logo' />
              <div className=" wd-100 clearfix">
                <div className="col col-3_6 col-sm-1_1 map fr">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4996.539884768586!2d85.33088669517699!3d27.68369856365291!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1994994af341%3A0x3ad169881f4dc56b!2sSpiralogics+International+Nepal!5e0!3m2!1sen!2snp!4v1542170538660"></iframe>
                </div>
                <div className="col col-2_5 col-sm-1_1 details clearfix">
                  <div className=" wd-100 clearfix mrgb-15">
                    <div className="col col-1_4">
                      <img src="/assets/images/icons/map-marker.png" className="fr" alt="map-marker" />
                    </div>
                    <div className="col col-3_4">
                      <h5>
                        New Baneshwor, Shankhamul Tower, Kathmandu, Nepal
                      </h5>
                    </div>
                  </div>
                  <div className=" wd-100 clearfix mrgb-15">
                    <div className="col col-1_4">
                      <img src="/assets/images/icons/phone.png" className="fr" alt="map-marker" />
                    </div>
                    <div className="col col-3_4">
                      <h5>
                        <a className="culprit-2" href="tel:01-4787644">01-4787644</a>
                      </h5>
                    </div>
                  </div>
                  <div className=" wd-100 clearfix mrgb-15">
                    <div className="col col-1_4">
                      <img src="/assets/images/icons/envelope.png" className="fr" alt="map-marker" />
                    </div>
                    <div className="col col-3_4">
                      <h5>
                        <a className="culprit-2" href="mailto:info@spiralogics.com">info@spiralogics.com</a>
                      </h5>
                    </div>
                  </div>
                  <div className=" wd-100 clearfix mrgb-15">
                    <div className="col col-1_4">
                      <i className="fr fas fa-blog"></i>
                    </div>
                    <div className="col col-3_4">
                      <h5>
                        <a className="culprit-2" href="http://spiralogicsit.com/index.php/blog/">http://spiralogicsit.com/index.php/blog/</a>
                      </h5>
                    </div>
                  </div>
                  <div className=" wd-100 clearfix mrgb-15">
                    <div className="col col-1_4">
                      <i className="fr fab fa-facebook-f"></i>
                    </div>
                    <div className="col col-3_4">
                      <h5>
                        <a className="culprit-2" href="https://www.facebook.com/spiralogicsintl/">https://www.facebook.com/spiralogicsintl/</a>
                      </h5>
                    </div>
                  </div>
                  <div className=" wd-100 clearfix mrgb-15">
                    <div className="col col-1_4">
                      <i className="fr fab fa-instagram"></i>
                    </div>
                    <div className="col col-3_4">
                      <h5>
                        <a className="culprit-2" href="https://www.instagram.com/spiralogicsinternational/">https://www.instagram.com/spiralogicsinternational/</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default GetInTouch
