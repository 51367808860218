import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import NavLinks from './NavLinks'
import UserNavLink from '../user/UserNavlink';

const Navbar = (props) => {
  const links = <NavLinks />
  // const authLinks = props.isloggedIn ? <UserNavLink /> : null

  return (
    <header>
      <div className="overlay"></div>
      <div className='container'>
        <div className='logo-section col col-1_4 col-md-1_6 col-pt-1_6'>
          <NavLink to='/'>
            <img
              src='/assets/images/logo/logocolor.png'
              alt='Spirathon logo Colorful'
              className='logo logo-color'
            />
            <img
              src='/assets/images/logo/logowhite.png'
              alt='Spirathon logo White'
              className='logo logo-white'
            />
          </NavLink>
        </div>
        <nav className='col col-3_4 col-md-5_6 col-pt-5_6 clearfix'>
          <button className='triggger-btn fr'>
            <img
              src='/assets/images/icons/burger.png'
              alt='Burger Menu Icon'
              className='menu-btn'
            />
            <img
              src='/assets/images/icons/close.png'
              alt='Burger Menu Icon'
              className='close-btn'
            />
          </button>
          {links}
        </nav>
      </div>
      {/* {authLinks} */}
    </header>
  )
}

export default Navbar
