import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { disableWorkShop1 } from "../../shared/workshopDisable";
const parseString = require("xml2js").parseString;

class WorkShop extends Component {
  constructor() {
    super();
    this.state = {
      Workshops: []
    };
  }
  componentDidMount() {
    axios.get(`/xmls/workshop.xml`).then(response => {
      parseString(response.data, (error, result) => {
        if (error) {
          //console.log('error')
        } else {
          // console.log(result['Spirathon']['Workshop'][0]['Description'])
          this.setState({
            Workshops: [...result["Spirathon"]["Workshop"][0]["Description"]]
          });
        }
      });
    });
  }
  renderSpeaker = itemSpeaker => {
    //console.og(itemSpeaker)
    return itemSpeaker.map((item, index) => (
      <div className="col col-1_4 col-pt-1_4 col-sm-1_2" key={index}>
        <div className="textbox">
          <div className="thumbnail-box2">
            <img src={item.image[0].src[0]} alt={item.image[0].title[0]} />
          </div>
          <h2>{item.Name[0]}</h2>
          <h6>{item.Institution[0]}</h6>
        </div>
      </div>
    ));
  };
  render() {
    return (
      <main>
        <div className="elseall clearfix">
          <div className="container">
            {/* <h1 className="t-a-c cullprit">Updating soon</h1> */}
            <div className="topp wd-100 clearfix">
              <div className="col col-3_5 col-sm-1_1">
                <h1>Brush up your skills with Spirathon Workshop.</h1>
                <div className="col col-1_3 col-sm-1_1">
                  <label>
                    This year, Spiralogics is organising 5 different workshop
                    throughout December as a pre-event leading up to it's main
                    event - Spirathon. It is a series of workshop on AI where
                    different AI experts will facilitate the event.
                  </label>
                </div>
              </div>
              <div className="col col-2_5 col-sm-1_1">
                <div className="thumbnail-box2 hide-sm">
                  <img src="/assets/images/graphics/workshop_1-02.png" alt="" />
                </div>
              </div>
            </div>
            {this.state.Workshops.map((item, index) => {
              const dDate = item.DisableDate ? item.DisableDate[0] : null;
              const isDisabled = item.DisableDate
                ? disableWorkShop1(dDate)
                : false;
              const mySpeaker = item.Speaker;
              return (
                <div
                  className={
                    "wd-100 clearfix steps ws " + (index % 2 === 0 ? "lr" : "")
                  }
                  key={index}
                >
                  <div className="col col-1_6 hide-sm blank">
                    <div />
                  </div>
                  <div className="col col-2_6 col-pt-2_6 col-sm-1_1 step clearfix">
                    <div className="col col-1_5">
                      <h1>{index + 1}</h1>
                    </div>
                    <div className="col col-4_5">
                      <h3>{item.Title[0]}</h3>
                      <label>{item.Content[0]}</label>
                      <Link
                        to={`/ticketing/${item.Title[0]}`}
                        params={{ id: "hello" }}
                        className="workshop-page"
                      >
                        {isDisabled ? "Learn more" : "Book this workshop"}
                      </Link>
                      {/* <label>Workshop Ended</label> */}
                    </div>
                  </div>
                  {this.renderSpeaker(mySpeaker)}
                </div>
              );
            })}
          </div>
        </div>
      </main>
    );
  }
}

export default WorkShop;
