import React, { Component } from "react";
import axios from "axios";
import TextRender from "./renderText";
import RenderSquareImage from "./imageRenderSquare";
const parseString = require("xml2js").parseString;

class SpirathonHistory extends Component {
  constructor() {
    super();
    this.state = {
      // EventDays: [],
      Mentors: [],
      CoreTeam: [],
      WorkShop: [],
      Volunteers: []
    };
  }
  componentDidMount() {
    axios.get(`xmls/spirathon17.xml`).then(response => {
      //console.og(response)
      parseString(response.data, (error, result) => {
        if (error) {
          //console.og('error')
        } else {
          //console.og(result['Spirathon']['EventDays'][0]['image'])
          //console.og(result['Spirathon']['Volunteers'][0]['image'])
          //console.og(result['Spirathon']['WorkShop'][0]['image'])
          this.setState({
            WorkShop: [...result["Spirathon"]["WorkShop"][0]["image"]]
            // EventDays: [...result['Spirathon']['EventDays'][0]['image']],
            // Volunteers: [...result['Spirathon']['Volunteers'][0]['image']]
          });
        }
      });
    });
    axios.get(`xmls/about.xml`).then(response => {
      parseString(response.data, (error, result) => {
        if (error) {
          //console.log('error')
        } else {
          //console.og(result)
          this.setState({
            Mentors: [...result["Spirathon"]["Mentors"][0]["Description"]],
            CoreTeam: [...result["Spirathon"]["Core"][0]["Description"]],
            Volunteers: [...result["Spirathon"]["Volunteers"][0]["Description"]]
          });
        }
      });
    });
  }

  render() {
    return (
      <main>
        <div className="elseall clearfix">
          <div className="container">
            <div className="restbody">
              <div className="clearfix">
                <div className="col col-1_3 col-sm-1_1 col-pt-3_4">
                  <div className="padlr-15 mrgb-30">
                    <h1>What is spirathon?</h1>
                    <label>
                      Spirathon is an annual appathon where a team consisting of
                      maximum 3 members will need to turn the raw idea they have
                      into a real life computer application. The team can build
                      their application in any computer language and platform.
                      Spiralogics as well as other high profiled software
                      companies will be providing mentorship to each of the
                      team. Professionals from the top IT companies and
                      non-government organisations will be judging the event and
                      choosing the winners.
                    </label>
                  </div>
                </div>
                <div className="col col-1_3 col-sm-1_1 col-pt-3_4">
                  <div className="padlr-15 mrgb-30 mrgt-30">
                    <h2>Spirathon 2019</h2>
                    <p>
                      This year’s theme is AI for Impact. Besides the appathon,
                      we will also be inviting keynote speakers and conducting
                      workshop during the pre-events.
                    </p>
                    <p>
                      We will be selecting teams from different IT colleges to
                      compete with each other for the winning title. Each team
                      will be assigned a mentor 2-weeks prior to the main event.
                      They will present their application and give a demo in the
                      finale. Each team will be scored on different criteria by
                      the judges.
                    </p>
                  </div>
                </div>
                <div className="col col-1_3 col-sm-1_1 col-pt-1_1">
                  <div className="padlr-15 mrgb-30 mrgt-30">
                    <h2>Spiralogics International</h2>
                    <p>
                      Spiralogics is an American based software development
                      company. It currently employs over 70 team members in
                      Nepal and USA. The company primarily builds application
                      for the American Health Insurance industry. Some of its
                      clients include Walmart, Examworks and AIG. Most of the
                      clients are among the Fortune 500 Companies.
                    </p>
                    <p>
                      Spiralogics was established in 2002 in America and 2009 in
                      Nepal. With 15+ experience in the software industry, the
                      company has always desired to teach the new and upcoming
                      developers with what it has learned over the years. Out of
                      that idea, Spiralogics Institute of Technology (SIT) was
                      born in 2017.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col-1_1">
                <div className="padlr-15 mrgb-30 mrgt-30">
                  <h2>Details</h2>
                  <p>
                    You can find the registration form which you need to fill up
                    after signing up for the event. Each team will need to
                    submit their team name, team members name, project title,
                    1-2 min short intro video about the project/app, project
                    overview, associated institution/ college, member’s email
                    and phone number.
                  </p>
                </div>
              </div>
              <div className="col col-1_1">
                <div className="padlr-15 mrgb-30 mrgt-30">
                  <h2>Glimpse of Spirathon 2017</h2>
                  <div className="col col-1_1 newbox">
                    <h5>Spirathon 2017 Winner's Video</h5>
                    <iframe
                      src="https://www.youtube.com/embed/dh3x5yiXL9c"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <div className="col col-1_1">
                    <h5>Event</h5>
                    <RenderSquareImage images={this.state.WorkShop} />
                  </div>
                </div>
              </div>
              <div className="col col-1_1">
                <div className="padlr-15 mrgb-30 mrgt-30">
                  <h2>Organising Team of 2019</h2>
                  <div className="col col-1_1">
                    <h5 className="mrgb-30">Core Team</h5>
                    <div className="wd-100 clearfix prize">
                      {this.state.CoreTeam.map((item, index) => {
                        return (
                          <div className="col col-1_5 col-sm-1_1" key={index}>
                            <div className="textbox">
                              <div className="thumbnail-box">
                                <img
                                  src={item.image[0].src[0]}
                                  alt={item.image[0].title[0]}
                                />
                              </div>
                              <h2>
                                <TextRender text={item.Name[0]} />
                              </h2>
                              <h6>
                                <TextRender text={item.Institution[0]} />
                              </h6>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col col-1_1">
                    <h5 className="mrgb-30">Website Team</h5>
                    <div className="wd-100 clearfix prize">
                      {this.state.Mentors.map((item, index) => {
                        return (
                          <div className="col col-1_5 col-sm-1_1" key={index}>
                            <div className="textbox">
                              <div className="thumbnail-box">
                                <img
                                  src={item.image[0].src[0]}
                                  alt={item.image[0].title[0]}
                                />
                              </div>
                              <h2>
                                <TextRender text={item.Name[0]} />
                              </h2>
                              <h6>
                                <TextRender text={item.Institution[0]} />
                              </h6>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-1_1">
                <div className="padlr-15 mrgb-30 mrgt-30">
                  <div className="col col-1_1">
                    <h5 className="mrgb-30">Volunteer Team</h5>
                    <div className="wd-100 clearfix prize">
                      {this.state.Volunteers.map((item, index) => {
                        return (
                          <div
                            className="col col-1_5 col-sm-1_1 ihavefixedheight"
                            key={index}
                          >
                            <div className="textbox">
                              <div className="thumbnail-box">
                                <img
                                  src={item.image[0].src[0]}
                                  alt={item.image[0].title[0]}
                                />
                              </div>
                              <h2>
                                <TextRender text={item.Name[0]} />
                              </h2>
                              <h6>
                                <TextRender text={item.Institution[0]} />
                              </h6>
                              <p>
                                <TextRender text={item.Volunteerteam[0]} />
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default SpirathonHistory;
