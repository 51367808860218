import React, { Component } from 'react'
// import RenderCircularImage from './imageRenderCircle'
import axios from 'axios'
import { baseUrl, imageUrl } from '../../config/apiUrls'
import { setHeaders } from '../../shared/shared'
import { Link } from 'react-router-dom'
import TextRender from './renderText';
import Loader from './loader';
const parseString = require('xml2js').parseString


class FinalistsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userList: [],
    }
  }

  componentDidMount() {
    try {
      axios
        .get(`${baseUrl}listAllParticipants`)
        .then(response => {
          if (response && response.data) {
            // set state here
            this.setUserList(response.data)
          }
        })
        .catch(err => {
          //console.log(err)
        })
    } catch (err) {
      //console.log('error fetching users list')
    }
  }

  setUserList(list) {
    this.setState({ userList: list })
    //console.log(list)
  }

  render() {
    if (this.state.userList && this.state.userList.length !== 0) {
      return (
        <main>
          <div className='elseall clearfix'>
            <div className='container'>
              {/* <h1 className="t-a-c cullprit">Updating soon</h1> */}
              <div className='topp wd-100 clearfix'>
                <div className='col col-3_5 col-sm-1_1'>
                  <h1>Top 5 Finalists</h1>
                  {/* <div className='col col-1_3 col-sm-1_1'>
                    <label>
                      Five teams will be competing with one another for the winning title in Spirathon 2019. Here are the list of teams that will be participating and competing with one another in Spirathon 2019 <span className="hashtag">#AIforImpact</span>.
                  </label>
                  </div> */}
                </div>
                {/* <div className='col col-2_5 col-sm-1_1'>
                  <div className='thumbnail-box2 hide-sm'>
                    <img
                      src='/assets/images/graphics/goodhands-02.png'
                      alt=''
                    />
                  </div>
                </div> */}
                <div className="col col-1_1 mrgt-30 clearfix">
                  <div className="wd-100 clearfix">
                    {this.state.userList.map((eachTeam, index) => {
                      if (eachTeam && eachTeam.isSelectedValue === true && eachTeam.isTeamRegistered) {
                        return (
                          <div className="col col-1_3 col-sm-1_1" key={index}>
                            <div className="padlr-15">
                              <div className="oppo-box">
                                <div className="img-box">
                                  <img src={eachTeam.projectBanner ? imageUrl + eachTeam.projectBanner : "/assets/images/jpgs/team/team.jpg"} alt="team image here" />
                                </div>
                                <div className="text-box">
                                  <h4>
                                    Team Name: <span>{eachTeam.teamName}</span>
                                  </h4>
                                  <h5>
                                    Project Name: <span>{eachTeam.projectTitle}</span>
                                  </h5>
                                  <Link to={`/details/${eachTeam._id}`}>View this Project</Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      } else {
                        return (
                          <div key={index}></div>
                        )
                      }
                    })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )
    } else {
      return (<Loader />)
    }
  }
}
export default FinalistsPage

