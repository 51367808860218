import React from "react";

const Conference = () => {
  return (
    <div className="elseall clearfix">
      <div className="container">
        <div className="contact-box wd-100 clearfix">
          <div className="conferencedetail">
            <h4>Spirathon AI Conference</h4>
            <br />
            <label>
              Spiralogics is hosting a first of it's kind AI Conference in Nepal
              this February.
            </label>
            <br />
            <br />
            <p>
              This year, we are focused on exploring and explaining the ways how
              AI can create positive impact on different aspects of our life. It
              is a platform which will act as a driving force in encouraging the
              implementation of AI (especially in the Nepalese environment).
            </p>
            <br />
            <p>
              The conference will include speakers with extensive knowledge on
              AI. This will be your chance to network with veterans and
              revolutionaries of the IT industry.
            </p>
            <br />
            <p>
              The program will include AI Application Demo and Presentation, Key
              Note Speakers and Awards Distribution.
            </p>
            <br />
            <h6>
              Please make sure to apply by January 20, 2019 as we have limited
              seats.
            </h6>
            <br />
            <h5>
              <span>Ticket Price</span>: Rs 500 for Professionals, Rs 300 for
              Students
            </h5>
            <h5>
              <span>Venue</span>: Bougainvilla, Tripureshwor
            </h5>
            <h5>
              <span>Date</span>: Saturday, 2nd Feb, 2019
            </h5>
            <h5>
              <span>Time</span>: 10:30 am to 3:00 pm
            </h5>
            <br />
            <p>
              You can pay via our esewa ID - spirathon@spiralogics.com or  via cash by visiting our office located in Shankamul Tower, 4th Floor.
            </p>
            <p>
              For any inquiry, please contact at 01-4787644 between 9:00 am to
              4:00 pm
            </p>

            <br />
          </div>
          <h4>Schedule</h4>
          <hr />
          <div className="conferenceschedule">
            <div className="schedule">
              <div className="s-header wd-100 clearfix">
                <div className="col col-1_4 col-md-1_3">
                  10:30 am - 11:00 am{" "}
                </div>
                <div>Registration</div>
              </div>
              <div className="s-header wd-100 clearfix">
                <div className="col col-1_4 col-md-1_3">11:00 am - 11:10 am</div>
                <div>Welcome speech by host</div>
              </div>
              <div className="s-header wd-100 clearfix">
                <div className="col col-1_4 col-md-1_3">11:10 am - 11:20 am</div>
                <div>Opening Speech by Samir Upadhyay (CEO of Spiralogics)</div>
              </div>
              <div className="s-header wd-100 clearfix">
                <div className="col col-1_4 col-md-1_3">11:20 am - 11:30 am</div>
                <div>Speech by Chief Guest - Mahabir Pun</div>
              </div>
              <div className="s-header wd-100 clearfix">
                <div className="col col-1_4 col-md-1_3">11:30 am - 12:50 pm</div>
                <div>Team Presentation + Demo</div>
              </div>
              <div className="s-header wd-100 clearfix">
                <div className="col col-1_4 col-md-1_3">12:50 pm - 1:10 pm</div>
                <div>First Keynote Speaker - Suraj Regmi
                  <div className="imagespeaker">
                    <img src="assets/images/jpgs/event/speaker-02.jpg" alt="suraj_Regmi" />
                  </div>
                </div>
              </div>
              <div className="s-header wd-100 clearfix">
                <div className="col col-1_4 col-md-1_3">1:10 pm - 1:30 pm</div>
                <div>Second Keynote Speaker - Bishesh Khanal
                  <div className="imagespeaker">
                    <img src="assets/images/jpgs/event/speaker-01.jpg" alt="Bishesh Khanal" />
                  </div>
                </div>
              </div>
              <div className="s-header wd-100 clearfix">
                <div className="col col-1_4 col-md-1_3">1:30 pm - 2:00 pm</div>
                <div>Break</div>
              </div>
              <div className="s-header wd-100 clearfix">
                <div className="col col-1_4 col-md-1_3">2:00 pm - 2:20 pm</div>
                <div>Third Keynote Speaker - Dr. Pramod Parajuli
                <div className="imagespeaker">
                    <img src="assets/images/jpgs/event/speaker-04.jpg" alt="Pramod Parajuli" />
                  </div>
                </div>
              </div>
              <div className="s-header wd-100 clearfix">
                <div className="col col-1_4 col-md-1_3">2:20 pm - 2:40 pm</div>
                <div>
                  Final Keynote Speaker - Saunak Ranjitkar
                  <div className="imagespeaker">
                    <img src="assets/images/jpgs/event/speaker-03.jpg" alt=" Saunak Ranjitkar" />
                  </div>
                </div>
              </div>
              <div className="s-header wd-100 clearfix">
                <div className="col col-1_4 col-md-1_3">2:40 pm - 3:00 pm</div>
                <div>Prize Distribution + Closing Remarks</div>
              </div>
              <div className="s-header wd-100 clearfix">
                <div className="col col-1_4 col-md-1_3">3:00 pm - 3:30 pm</div>
                <div>Floor open for Networking</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conference;
